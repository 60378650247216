exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-2-js": () => import("./../../../src/pages/app2.js" /* webpackChunkName: "component---src-pages-app-2-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-corporate-2-js": () => import("./../../../src/pages/corporate2.js" /* webpackChunkName: "component---src-pages-corporate-2-js" */),
  "component---src-pages-corporate-js": () => import("./../../../src/pages/corporate.js" /* webpackChunkName: "component---src-pages-corporate-js" */),
  "component---src-pages-hospital-js": () => import("./../../../src/pages/hospital.js" /* webpackChunkName: "component---src-pages-hospital-js" */),
  "component---src-pages-hosting-js": () => import("./../../../src/pages/hosting.js" /* webpackChunkName: "component---src-pages-hosting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-institute-js": () => import("./../../../src/pages/institute.js" /* webpackChunkName: "component---src-pages-institute-js" */),
  "component---src-pages-interior-2-js": () => import("./../../../src/pages/interior2.js" /* webpackChunkName: "component---src-pages-interior-2-js" */),
  "component---src-pages-interior-js": () => import("./../../../src/pages/interior.js" /* webpackChunkName: "component---src-pages-interior-js" */),
  "component---src-pages-restaurant-js": () => import("./../../../src/pages/restaurant.js" /* webpackChunkName: "component---src-pages-restaurant-js" */),
  "component---src-pages-saas-2-js": () => import("./../../../src/pages/saas2.js" /* webpackChunkName: "component---src-pages-saas-2-js" */),
  "component---src-pages-saas-js": () => import("./../../../src/pages/saas.js" /* webpackChunkName: "component---src-pages-saas-js" */),
  "component---src-pages-showcase-js": () => import("./../../../src/pages/showcase.js" /* webpackChunkName: "component---src-pages-showcase-js" */)
}

